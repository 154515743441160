.covered-industry-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.covered-industry-inner-container{
    height: 80px;
    display: flex;
    justify-content: center;
}

.covered-industry-item {
    border-radius: 50px;
    opacity: 0.8;
    text-align: center;
    color: white;
    width: 15%;
}

.covered-industry-item > .section-table{
    height: 60%;
}

.covered-industry-item-title{
    font-size: 18px;
}

.covered-industry-item-content{
    font-size: 16px;
}

.covered-industry-image{
    margin: auto;
}

@media(max-width: 1024px){
    .covered-industry-item-title {
        font-size: 14px;
    }
}

@media(max-width: 767px) {
    .covered-industry-item > .section-table{
        height: 130px;
    }
    .covered-industry-container {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .covered-industry-item{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
        margin-bottom: 40px;
    }
    .covered-industry-inner-container{
        height: 120px;
    }
    .covered-industry-item-title{
        font-size: 16px;
    }
}

@media(max-width: 468px){
    .covered-industry-item-title{
        font-size: 15px;
    }
}