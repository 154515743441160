/* Hiragino Sans CNS */
@font-face {
    font-family: "Hiragino Sans CNS";
    src: url("./HiraginoSansCNS-Bold.otf");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Hiragino Sans CNS";
    src: url("./HiraginoSansCNS-Regular.otf");
    font-weight: normal;
    font-display: swap;
}

.font-face-zh-HK {
    font-family: 'Hiragino Sans CNS' !important;  
    font-display: swap;
}
