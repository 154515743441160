/* Hiragino Sans GB */
@font-face {
    font-family: "Hiragino Sans GB";
    src: url("./HiraginoSansGB-Bold.otf");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Hiragino Sans GB";
    src: url("./HiraginoSansGB-Regular.otf");
    font-weight: normal;
    font-display: swap;
}

.font-face-zh-CN {
    font-family: 'Hiragino Sans GB' !important;
    font-display: swap;
}
