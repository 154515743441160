.spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
    margin : 0px;
}


@media (max-width: 1440px ) {
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width : 40%;
    }
    .section-outscope-top-sticky-image{
        top : 25%;
    }
    .section-outscope-bottom-sticky-image{
        top : -25%;
    }
}

@media (max-width: 1280px ) {
    .section-outscope-top-sticky-image{
        top : 35%;
    }
}

@media (max-width: 1120px ) {
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width : 50%;
    }
    .section-outscope-top-sticky-image{
        top : 40%;
    }
}

@media (max-width: 1024px ) {
    .section-outscope-top-sticky-image{
        top : 45%;
    }
    .section-benefit{
        margin-top: 0px;
    }
}

@media (max-width: 968px ) {
    .section-outscope-top-sticky-image{
        top : 50%;
    }
}

@media (max-width: 888px ) {
    .section-outscope-top-sticky-image{
        top : 60%;
    }
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width : 60%;
    }
}

@media (max-width: 800px ) {
    .section-outscope-top-sticky-image{
        top : 65%;
    }
}

@media (max-width: 767px) {
    .section-outscope-top-sticky-image{
        top : 0;
    }
    .section-outscope-bottom-sticky-image{
        top : 0;
    }
    .section-benefit{
        margin-top: 20px !important;
    }
    .spot-payment-section-table{
        min-height: auto;
    }
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        margin : auto;
        width: 40%;
    }
    .covered-industry-container {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .covered-industry-item{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
        margin-bottom: 20px;
    }
}

@media (max-width: 568px) {
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width: 50%;
    }
}
@media (max-width: 475px) {
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width: 60%;
    }
}

@media (max-width: 375px) {
    .spot-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
        width: 75%;
    }
}