

.slider-wrap {
  height: 100%;
  width: 100%; 
  position: relative;
  margin-top: 30%;
}
.slider-wrap .slider {
  position: absolute;
  width: 100%;
 }

.slider-item {
  width: 100%;
  padding: 5px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 4px 9px #f1f1f4;
  position: absolute;
}

.slider-item > .sector-detail {
  background-image: linear-gradient(180deg, rgb(255, 64, 33, 0.2), rgb(41, 135, 255, 0.2));
  background-repeat: repeat;
  border-radius: 20px;
  width: 70%;
  padding: 20px;
}

.portfolio-container {
  position: relative;
}

.portfolio-circle{
  position: absolute;
  left: -100%;
  width: 200%;
}

.portfolio-circle-text{
  position: absolute;
  font-size: 64px;
}

.portfolio-circle-text:nth-child(2){
  top: 10%;
  left: 40%;
}

.portfolio-circle-text:nth-child(3){
  top: 20%;
  left: 50%;
}

.portfolio-circle-text:nth-child(4){
  top: 30%;
  left: 50%;
}

.portfolio-circle-text:nth-child(5){
  top: 40%;
  left: 40%;
}

.top-slider-item:hover{
  cursor: pointer;
}

.nav-circle {
  width: 400px;
  height: 400px;
  border: solid 1px red;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
}

.slider-item > .sector-detail > img {
  display: table;
  margin: auto;
  margin-top: 10px;
}

.slider-item > .sector-number {
  text-align: center;
  font-size: 48px;
  display: table;
  margin: auto;
}

.portfolio-container >  .no-boundary-container {
  max-width: inherit !important;
}

.portfolio-content-container{
  margin-top: 20%;
}

#portfolio-circle-container{
  position: relative;
}

#portfolio-inside-circle{
  width: 190%;
  position: absolute;
  left: -95%;
}

#portfolio-outside-circle{
  width: 200%;
  position: absolute;
  left: -100%;
  top: 0;
}

.circle-tag-number{
  font-size: 64px;
  position: absolute;
}

.portfolio-and-more{
  display: table;
  margin: auto;
  color: white;
}

@media (max-width: 1024px ) {
  .circle-tag-number{
    font-size: 48px;
  }
}

@media (max-width: 968px ) {
  .circle-tag-number{
    font-size: 36px;
  }
}
@media (max-width: 824px ) {
  .circle-tag-number{
    font-size: 28px;
  }
  .portfolio-content-container > .section-table > .section-content > .section-content-title{
    font-size: 28px;
  }
}

@media (max-width: 767px){
  #portfolio-circle-container{
    margin-top: -40px;
  }
  #portfolio-inside-circle{
    width: 95%;
    top: -95%;
    left: 0%;
  }
  
  #portfolio-outside-circle{
    width: 100%;
    top: -100%;
    left: 0%;
  }
  .portfolio-content-container{
    margin-top: 40px;
  }
  .slider-wrap{
    margin-top: 40px;
    min-height: 400px;
  }
}

@media (max-width: 500px){
  .circle-tag-number{
    font-size: 24px;
  }
}