.solution-container {
    display: flex;
    flex-direction: row;
}

.solution-item {
    background-color: rgb(228,228,229);
    flex: 15%;
    padding: 2.5%;
    margin: 2.5%;
    border-radius: 50px;
    opacity: 0.8;
}

.solution-item-online-payment {
    color: #ff3b1c;
}

.solution-item-spot-payment {
    color: #36b5fc;
}

.solution-item-wechat-mini-program {
    color: rgb(125,204,112);
}

.solution-item-title{
    font-size: 24px;
    text-align: center;
}

.solution-item-content{
    font-size: 14px;
    text-align: center;
}

.solution-outer-container > .no-boundary-container{
    margin: auto !important;
}
@media (max-width: 1320px ) {
    .section-outscope-image {
        top: 30%;
    }
}

@media (max-width: 1200px ) {
    .section-outscope-image {
        top: 35%;
    }
    .solution-item-title {
        font-size: 23px;
    }
}

@media (max-width: 1120px ) {
    .section-outscope-image {
        top: 40%;
    }
    .solution-item-title {
        font-size: 22px;
    }
}

@media (max-width: 1024px ) {
    .section-outscope-image {
        top: 40%;
    }
    .solution-item-title {
        font-size: 21px;
    }
}

@media (max-width: 960px ) {
    .solution-item-title {
        font-size: 18px;
    }
}

@media (max-width: 888px ) {
    .solution-item-title {
        font-size: 16px;
    }
}

@media (max-width: 868px ) {
    .section-outscope-image {
        top: 45%;
    }
}

@media (max-width: 800px ) {
    .solution-item-title {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .section-outscope-image{
        width: 100%;
        position: relative;
        top: 0;
    }
    .solution-integration-section-content{
        font-size: 26px;
        text-align: center;
    }
    .solution-item-outer-container{
        overflow: hidden;
    }
    .solution-item-container{
        width: 95%;
        height: 360px;
        margin-bottom: 40px;
    }
    .solution-item{
        margin: auto;
        flex-basis: auto;
        height: 360px;
        width: 360px;
        padding: 10%;
    }
    .solution-item-title{
        font-size: 26px;
        margin-bottom: 20px !important;
    }
    .solution-item-content{
        font-size: 18px;
    }
    .solution-mobile-item{
        position: absolute;
    }
    .solution-item-image{
        margin-top: 20%;
    }
}

@media (max-width: 680px) {
    .solution-integration-section-content{
        font-size: 24px;
    }
    .solution-item-title{
        font-size: 24px;
    }
}

@media (max-width: 580px) {
    .solution-integration-section-content{
        font-size: 22px;
    }
    .solution-item-title{
        font-size: 22px;
    }
    .solution-item-content{
        font-size: 16px;
    }
}
@media (max-width: 568px ) {
    .solution-item-container{
        height: 300px;
    }
    .solution-item{
        height: 300px;
        width: 300px;
    }
}

@media (max-width: 468px) {
    .solution-integration-section-content{
        font-size: 18px;
    }
    .solution-item-title{
        font-size: 18px;
    }
    .solution-item-content{
        font-size: 14px;
    }
}

@media (max-width: 425px) {
    .solution-item-container{
        height: 240px;
    }
    .solution-item{
        height: 240px;
        width: 240px;
    }
}