@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: normal;
    src: url('Gotham-Book.woff') format('woff'),
    url('Gotham-Book.woff2') format('woff2'),
    url('Gotham-Book.ttf') format('truetype');
    font-display: swap;
}
/*
@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 400;
    src: url('./GothamBookItalic.ttf') format('ttf');
}
*/
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: url('./Gotham-Light.otf');
    font-display: swap;
}
/*
@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 300;
    src: url('./GothamLightItalic.otf');
}
*/
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: url('./Gotham-Medium.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: url('./GothamMedium_1.ttf');
    font-display: swap;
}
/*
@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src: url('./GothamMediumItalic.otf');
}
*/
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: url('./GothamBold.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: url('./Gotham-Bold.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Bold Italic';
    font-style: italic;
    font-weight: 700;
    src: 
    url('./Gotham-BoldItalic.otf') format('opentype'),
    url('./GothamBoldItalic.woff') format('woff'),
    url('./GothamBoldItalic.woff2') format('woff2'),
    url('./GothamBoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Gotham Black';
    font-style: normal;
    font-weight: 500;
    src: url('./Gotham-Black.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Light';
    font-style: normal;
    font-weight: 500;
    src: url('./Gotham-Light.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Thin';
    font-style: normal;
    font-weight: 500;
    src: url('./Gotham-Thin.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham XLight';
    font-style: normal;
    font-weight: 500;
    src: url('./Gotham-XLight.otf');
    font-display: swap;
}
/*
@font-face {
    font-family: 'Gotham Book';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham Book'), url('./GothamBookItalic.ttf') format('ttf');
}
*/
@font-face {
    font-family: 'Gotham Thin';
    font-style: italic;
    font-weight: 500;
    src: url('./Gotham-ThinItalic.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Ultra';
    font-style: italic;
    font-weight: 500;
    src: url('./Gotham-UltraItalic.otf');
    font-display: swap;
}
@font-face {
    font-family: 'Gotham XLight';
    font-style: italic;
    font-weight: 500;
    src: url('./Gotham-XLightItalic.otf');
    font-display: swap;
}

.font-face-en-US {
    font-family: 'Gotham' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-display: swap;
}

.font-face {
    font-family: 'Gotham' !important;
    font-display: swap;
}
