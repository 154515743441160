.submit-email-container{
    position: relative;
    height: 50px;
    font-size: 16px;
}

.submit-email-input{
    border-radius: 30px;
    border: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    color: grey;
}

.submit-email-button{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 30%;
    border-radius: 30px;
    border: none;
    background-image: linear-gradient(90deg,#ff8017, #2987ff);
    color: white;
    text-align: center;
    line-height: 50px;
}

.submit-email-icon{
    position: absolute;
    width: 20px;
    right: 8%;
    top: 25%;
}


@media (max-width: 768px ) {

    .submit-email-input{
        border-radius: 22px;
    }
    .submit-email-button{
        border-radius: 22px;
    }

}

@media (max-width: 468px ) {
    .submit-email-button{
        width: 35%;
    }
}

@media (max-width: 425px) {
    .submit-email-input{
        border-radius: 9px;
    }
    .submit-email-button{
        border-radius: 9px;
    }
}
@media (max-width: 375px ) {
    .submit-email-icon{
        display: none;
    }
}