.box-img-container img{
    width: 252px;
    height: 90px;
}


@media (max-width: 1024px ) {
    .about-us-content{
        font-size: 14px !important;
    }
    .about-us-title{
        font-size: 26px;
    }
} 

@media (max-width: 868px ) {
    .about-us-content{
        font-size: 13px !important;
    }
} 

@media (max-width: 800px ) {
    .about-us-content{
        font-size: 12.5px !important;
    }
} 


@media (max-width: 767px ) {
    .about-us-section-table{
        text-align: center;
    }
    
    .about-us-section-table > .section-content > .section-content-title{
        font-size: 42px !important;
    }

    .about-us-section-table > .section-content{
        font-size: 26px !important;
    }
} 

@media (max-width: 568px ) {
    
    .about-us-section-table > .section-content > .section-content-title{
        font-size: 36px !important;
    }

    .about-us-section-table > .section-content{
        font-size: 21px !important;
    }
} 


@media (max-width: 468px ) {
    
    .about-us-section-table > .section-content > .section-content-title{
        font-size: 32px !important;
    }

    .about-us-section-table > .section-content{
        font-size: 18px !important;
    }
} 

@media (max-width: 375px ) {
    
    .about-us-section-table > .section-content > .section-content-title{
        font-size: 26px !important;
    }

    .about-us-section-table > .section-content{
        font-size: 16px !important;
    }
} 