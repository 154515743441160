.consulting-reminder{
    font-size: 18px;
}

.consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
    font-size: 36px;
}


@media (max-width: 1124px ) {

    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
        font-size: 30px;
    }

    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .consulting-reminder {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .submit-email-container > .submit-email-button {
        width: 35%;
    }
    .consulting-content{
        font-size: 15px;
    }
}

@media (max-width: 968px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .submit-email-container > .submit-email-button {
        width: 35%;
    }
    .consulting-content{
        font-size: 14px;
    }
}

@media (max-width: 888px) {
    .consulting-content{
        font-size: 13px;
    }
}

@media (max-width: 800px) {
    .consulting-content{
        font-size: 11.5px;
    }
}

@media (max-width: 767px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .submit-email-container > .submit-email-button {
        width: 30%;
    }
    .consulting-content{
        font-size: 24px;
        text-align: center;
    }
    .consulting-join-us-content{
        text-align: center;
    }
}

@media (max-width: 680px) {
    .consulting-content{
        font-size: 22px;
    }
}

@media (max-width: 568px) {
    .consulting-content{
        font-size: 18px;
    }
}

@media (max-width: 468px) {
    .consulting-content{
        font-size: 16px;
    }
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
        font-size: 26px;
    }
}

@media (max-width: 425px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
        font-size: 24px;
    }
}

@media (max-width: 375px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
        font-size: 21px;
    }
}

@media (max-width: 340px) {
    .consulting-join-us-container > .row > .join-us-context > .section-table > .section-content > .section-content-title {
        font-size: 18px;
    }
    .consulting-reminder{
        font-size: 14px;
    }
}