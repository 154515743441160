.benefit-container {
    display: flex;
    flex-direction: row;
}

.benefit-item {
    flex: 30%;
    padding: 2.5%;
    border-radius: 50px;
    opacity: 0.8;
    text-align: center;
    color: white;
}

.benefit-item-title{
    font-size: 18px;
}

.benefit-item-content{
    font-size: 16px;
    text-align: center;
}

.online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
    top: -100%;
    background-image: linear-gradient(180deg,#ff8017, #2987ff);
}

@media (max-width: 1440px) {
    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 40%;
    }
}

@media (max-width: 1100px) {
    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 50%;
    }
}

@media (max-width: 900px) {
    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 60%;
    }
}

@media (max-width: 767px) {

    .online-payment-section-content{
        font-size: 26px;
        text-align: center;
    }

    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 40%;
        top: 0;
    }

    .benefit-table{
        margin: auto;
    }
    .benefit-table > .section-content > .section-content-title {
        font-size: 48px;
    }
    .benefit-container{
        display: block;
    }
    .benefit-item > .section-table {
        margin: auto;
    }
    .benefit-item-title{
        font-size: 36px;
    }
    .benefit-item-content{
        font-size: 26px;
    }
}


@media (max-width: 680px) {
    .online-payment-section-content{
        font-size: 24px;
    }
    .benefit-table > .section-content > .section-content-title {
        font-size: 42px;
    }
    .benefit-item-title{
        font-size: 34px;
    }
    .benefit-item-content{
        font-size: 24px;
    }
}

@media (max-width: 580px) {
    .online-payment-section-content{
        font-size: 22px;
    }
    .benefit-table > .section-content > .section-content-title {
        font-size: 38px;
    }
    .benefit-item-title{
        font-size: 32px;
    }
    .benefit-item-content{
        font-size: 22px;
    }
}

@media (max-width: 468px) {
    .online-payment-section-content{
        font-size: 18px;
    }
    .benefit-table > .section-content > .section-content-title {
        font-size: 36px;
    }
    .benefit-item-title{
        font-size: 26px;
    }
    .benefit-item-content{
        font-size: 18px;
    }

    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 50%;
    }
}

@media (max-width: 375px) {

    .online-payment-get-in-touch-container > .get-in-touch-container > .get-in-touch-button{
        width: 60%;
    }
    
    .banner-title{
        font-size: 36px;
    }

    .banner-sub-title{
        font-size: 36px;
    }
}