.contact-us-icon {
    width: 28px;
    height: 29px;
}

.icon {
    width: 24px;
}

.contact-us-form-error {
    color: red;
}

.contact-us-btn {
    border: 2px solid #f05422 !important;
    border-radius: 20px !important;
    margin-top: 20px !important;
    padding: 5px 15px;
}

.contact-us-btn:hover {
    background-color: #f05422 !important;
    color: white !important;
}

.contact-info-map{
    margin-bottom: 20px;
}

table td:first-child {
    width: 48px;
    height: 48px;
}

td img{
    display: block; 
    margin-left: 0px;
}

.contact-us-enquiry-form{
    border-radius: 15px;
    border: none;
    width: 100%;
    padding: 15px 15px 15px 20%;
    color: grey;
    opacity: 70%;
    margin-bottom: 20px;
}

#contact-us-enquiry-form-message{
    height: 120px;
    padding: 15px 15px 15px 25%;
    margin-bottom: 0px;
}

.contact-us-enquiry-form-label{
    border-radius: 15px;
    border: none;
    width: 15%;
    padding: 15px;
    color: grey;
    position: absolute;
    left: 0px;
    background-color: white;
}


#contact-us-enquiry-form-message-label{
    width: 20%;
}

.contact-us-map-container > .map-container > div > .amap-container {
    border-radius: 50px;
}

.contact-us-enquiry-form-block{
    position: relative;
}

#contact-us-enquiry-form-textarea-end{
    position: absolute;   
    bottom: 12px;
    right: 5px;
}

.contact-us-quick-contact-container{
    width: 40%;
}

.contact-us-quick-contact-container > .quick-contact-container > .quick-contact-button{
    width: 100%;
}
.contact-us-quick-contact-container > .quick-contact-container {
    margin: auto;
}

.contact-us-enquiry-form-submit-button{
    left: 0;
    background-image: linear-gradient(90deg,#2fd21e, #104268, #ff381a);
    width: 20%;
}
.billion-center-banner-title{
    font-size: 64px;
    line-height: 420px;
    color: white;
    display: table;
    margin: 0px auto;
}

@media (max-width: 1200px ) {
    .contact-us-enquiry-form-label{
        width: 20%;
    }

    #contact-us-enquiry-form-message-label{
        width: 25%;
    }
    .contact-us-quick-contact-container{
        width: 50%;
    }
    .contact-us-enquiry-form{
        padding-left: 25%;
    }
    #contact-us-enquiry-form-message{
        padding-left: 30%;
    }
}

@media (max-width: 991px) {

    .contact-us-section-title{
        font-size: 32px;
    }

    .contact-us-enquiry-form-label{
        width: 25%;
    }

    #contact-us-enquiry-form-message-label{
        width: 35%;
    }
    
    .contact-us-enquiry-form-submit-button{
        width: 30%;
    }
    .contact-us-quick-contact-container{
        width: 60%;
    }
    .contact-us-enquiry-form{
        padding-left: 30%;
    }
    #contact-us-enquiry-form-message{
        padding-left: 40%;
    }
}


@media (max-width: 767px) {
    #contact-information-table{
        margin: auto;
        width: 60%;
        margin-bottom: 20px;
    }
    #contact-information-table td {
        text-align: left;
    }
    .contact-us-quick-contact-container{
        margin-top: 20px;
        margin-bottom: 20px;
        margin: auto;
    }
    .contact-us-enquiry-form-container > .section-table {
        height: auto !important;
    }
    .contact-us-enquiry-form-container > .section-table > .section-content {
        margin: auto;
        display: table;
    }
    .contact-us-map-container > .section-table >.section-content {
        margin: auto;
        display: table;
    }
    .contact-us-map-container > .section-table >.section-content > .icon {
        width: 36px;
        padding-bottom: 16px;
    }
    .contact-us-map-container {
        margin-top: 20px;
    }
    .contact-us-map-container > .section-table {
        height: auto !important;
    }
    
    .contact-us-enquiry-form-submit-button{
        margin: auto;
        width: 40%;
    }
}

@media (max-width: 525px) {
    .billion-center-banner-title{
        font-size: 48px;
    }
}

@media (max-width: 450px) {
    .contact-us-enquiry-form-label {
        width: 30%;
    }
    #contact-us-enquiry-form-message-label{
        width: 40%;
    }    
    .contact-us-enquiry-form-submit-button{
        width: 60%;
    }
    .contact-us-enquiry-form-submit-button > .submit-email-icon{
        display: block;
    }
    .contact-us-enquiry-form{
        padding-left: 35%;
    }
    #contact-us-enquiry-form-message{
        padding-left: 45%;
    }
}

@media (max-width: 380px) {
    .contact-us-enquiry-form-label {
        width: 35%;
    }
    #contact-us-enquiry-form-message-label{
        width: 45%;
    }
    .contact-us-enquiry-form{
        padding-left: 40%;
    }
    #contact-us-enquiry-form-message{
        padding-left: 50%;
    }
}


@media (max-width: 350px) {
    .billion-center-banner-title{
        font-size: 36px;
    }
    .contact-us-enquiry-form-label {
        width: 40%;
    }
    #contact-us-enquiry-form-message-label{
        width: 50%;
    }
    .contact-us-enquiry-form-submit-button{
        width: 65%;
    }
    .contact-us-quick-contact-container{
        width: 65%;
    }
    .contact-us-enquiry-form{
        padding-left: 45%;
    }
    #contact-us-enquiry-form-message{
        padding-left: 55%;
    }
}