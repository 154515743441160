.quick-contact-container{
    position: relative;
    height: 50px;
    font-size: 16px;
}

.quick-contact-button{
    position: absolute;
    left: 0%;
    height: 100%;
    width: 40%;
    border-radius: 15px;
    border: none;
    background-image: linear-gradient(to top right,#ff381a, #104268, #2fd21e);
    color: white;
    text-align: center;
    line-height: 50px;
}

.quick-contact-icon{
    position: absolute;
    width: 20px;
    right: 8%;
    top: 25%;
}