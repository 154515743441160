/* clear */
.clear-bottom-half{
    margin-bottom: 10px;
}

.clear-bottom{
    margin-bottom: 20px;
}

.clear-bottom-2x{
    margin-bottom: 40px;
}

.clear-bottom-6x{
    margin-bottom: 120px;
}

.clear-top-half{
    margin-top: 10px;
}

.clear-top{
    margin-top: 20px;
}

.clear-top-2x{
    margin-top: 40px;
}

/* container */
.left-right-container {
    min-height: 280px;
}

.left-container {
    width: 50%;
    float: left;
}

.right-container {
    width: 50%;
    float: right;
}

.no-boundary-container{
    margin: 0px !important;
    max-width: fit-content !important;
}

.no-boundary-col-right{
    padding-right : 0px !important;
}

.no-boundary-col-left{
    padding-left : 0px !important;
}

.section-image {
    margin: auto;
}

/* section */
.section-header-container {
    margin-bottom:40px;
}

.section-header {
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px;
    text-align: center;
    text-transform:uppercase;
}

.section-header::after {
    content: "";
    position: absolute;
    left: 45%;
    width: 10%;
    height: 3px;
    bottom: 0;
    background: linear-gradient(to right, #F05422 98%,grey 2%);
}

.min-height-table{
    min-height: 420px;
}

.section-table{
    display: table; 
    width: 100%; 
    overflow: hidden;
}

.max-height{
    height: 100%;
}

.section-image-container{
    display: flex;
    justify-content: center;
    height: 100%;
}

.section-image{
    width: 100%;
    margin: auto;
    display: block;
}

.section-image-full{
    border-radius: 60px;
}


.section-image-left{
    border-radius: 60px 0px 0px 60px;
}

.section-image-left-top-right-bottom{
    border-radius: 60px 0px 60px 0px;
}

.section-outscope-image{
    width: 90%;
    top: 20%;
    position: absolute;
}

.section-outscope-top-sticky-image{
    width: 100%;
    top: 20%;
    position: absolute;
}

.section-outscope-bottom-sticky-image{
    width: 100%;
    top: -20%;
    position: absolute;
}

.section-image-right{
    border-radius: 0px 60px 60px 0px;
}

.stick-to-right{
    right: 0;
}

.stick-to-left{
    left: 0;
}


.section-content {
    color: white;
    font-size: 24px;
    font-weight: 400;
    display: table-cell;
    vertical-align: middle;
}

.section-content-title {
    font-size: 36px;
    font-weight: 400;
}

.down-arrow-button{
    position: absolute;
    bottom: 0;
    margin: 0 45%;
}

/* button */
.my-button {
    color: #F05422 !important;
    border-radius: 50px;
    border-color: #F05422 !important;
    transition: all 0.3s ease 0s;
    background: none;
}

.my-button:hover{
    color: white !important;
    border-color: #F05422 !important;
    background-color: #F05422 !important;
}

.banner-bg{
    text-align: center;
    padding-top: 10%;
    min-height: 900px;
    position: relative;
}

.banner-title{
    font-size: 48px;
    color: white;
    font-weight: bold;
}

.banner-sub-title{
    font-size: 42px;
}

.down-arrow-button{
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.down-arrow-image{
    width: 128px;
    height: 92px;
}

.mobile-only{
    display: none !important;
}
.ipad-mobile-only{
    display: none !important;
}

@media (max-width: 1440px ) {
    .section-content {
        font-size: 21px;
    }
} 

@media (max-width: 1280px ) {
    .section-content {
        font-size: 18px;
    }
    .banner-bg{
        padding-top: 15%;
    }
} 

@media (max-width: 1120px ) {
    .section-content {
        font-size: 17px;
    }
} 

@media (max-width: 1024px ) {
    .section-content {
        font-size: 16px;
    }
    .ipad-mobile-only{
        display: block !important;
    }
    .desktop-ipad-only{
        display: none !important;
    }
    .banner-bg{
        padding-top: 20%;
    }
} 

@media (max-width: 767px ) {
    /* container */
    .left-right-container {
        min-height: 140px;
    }
    .section-content{
        text-align: center;
    }
    .section-content-title{
        font-size: 32px;
        text-align: center;
    }
    .only-desktop-version{
        display: none;
    }
    .down-arrow-image {
        width: 96px;
        height: 78px;
    }
    .mobile-only{
        display: block !important;
    }
    .desktop-only{
        display: none !important;
    }
    .banner-bg{
        min-height: 640px;
    }
    .section-outscope-top-sticky-image{
        position: relative;
    }
    
    .section-outscope-bottom-sticky-image{
        position: relative;
    }
} 

@media (max-width: 568px ) {
    .section-content-title{
        font-size: 26px;
    }
    .banner-bg{
        padding-top: 25%;
    }
}

@media (max-width: 468px ) {
    .down-arrow-image {
        width: 64px;
        height: 52px;
    }
    .banner-bg{
        padding-top: 35%;
    }
}

