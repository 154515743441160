hr {
    display: block;
    height: 20px !important;
    border-style: inset !important;
    border-width: 1px !important;
    margin: 8px 4px !important;
}

.dropdown {
    padding: 0px;
}

/* Navbar (non-scroll) */
.my-nav {
    background: rgb(185, 202, 200, .8);
    top: 2.5% !important;
    right: 1.5% !important;
    left: 1.5% !important;
    border-radius: 35px;
    padding-left: 2% !important;
}

.my-nav-list{
    line-height: 40px;
    text-align: center;
    width: 100%;
    margin-left: 12.5% !important;
}

.border-nav {
    border-top : 2px solid #F05422;
    width: 95%;
    position: absolute;
    top: 0%;
    z-index: 10000000;
}

.nav-link{
    color: white !important;
}

/* Navbar link */
.navbar__link {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    padding-right: .5rem;
    padding-left: .5rem;
    padding-top: 15px !important;
    padding-bottom: 0px !important;
}

li > a.nav-link {
    margin-bottom: 5px !important;
}


a.navbar__link.nav-link{
    font-size: 18px;
    font-weight: 400 !important;
    font-style: normal !important;
}

.navbar .dropdown-item {
    padding : 0px !important; 
    position: relative;
    text-align: center;
}

.dropdown-border{
    border-top : 1px solid #F05422;
    width: 90%;
    position: absolute;
    top: 0%;
    left: 5%;
    z-index: 10000000;
}

.dropdown-item:hover {
    background-color: transparent !important;
}


.navbar__link--active {
    border-bottom: 3px solid #F05422;
    transition: border-bottom .5s ease-in-out;
}

.dropdown-menu {
    min-width: 100% !important;
    width: 100% !important;
    border: 0px !important;
    border-radius: 0rem 0rem 0.5rem 0.5rem !important;
    background-color: rgb(130,150,150,0.77) !important;
}

/* Language dropdown */
.my-nav-lang{
    margin-left: auto !important;
}

#language-dropdown-menu {
    left: -200% !important;
    width: 300% !important; 
    margin-top : 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#language-dropdown-toggle{
    height: 58px;
    margin-bottom: 0px !important;
    margin-top: 9px;
    padding-top: 15px !important;
}

.dropdown-toggle:after{
    display: none !important;
}

#language-dropdown-menu > button {
   padding: 10px !important;
}

#language-dropdown-menu > button > a {
    border-radius: 15px !important;
}

#language-dropdown-menu > button > a:hover {
    background-color: rgb(255, 255, 255, 0.4) !important;
}

.navbar__link{
    font-size: 14px;
}

nav {
    padding: 0px 50px !important;
}
.navbar.navbar-light .navbar-nav .nav-item.active > .nav-link:not(.active-link) {
    background-color: transparent !important;
}

li > .navbar__link.active, li > a.active-link {
    background-color: #F05422 !important;
    border-radius: 0px 0px 10px 10px;
}

@media (max-width: 1200px ) {
    .my-nav-list{
        margin-left: 5% !important;
    }
}

@media (max-width: 1124px ) {
    .my-nav-list{
        margin-left: 5% !important;
    }
    .nav-link{
        font-size: 16px !important;
    }
}

@media (max-width: 1020px ) {
    .my-nav-list{
        margin-left: 5% !important;
    }
    .nav-link{
        font-size: 14px !important;
    }
    nav {
        padding: 0px 30px !important;
    }
}

@media (max-width: 992px ) {
    .navbar .nav-item .nav-link {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
}

@media (max-width: 888px ) {
    .nav-link{
        font-size: 14px !important;
    }
    .my-nav-list{
        margin-left: 0% !important;
    }
    nav {
        padding: 0px 15px !important;
    }
}


@media (max-width: 832px ) {
    .nav-link{
        font-size: 12px !important;
    }
}

@media (max-width: 768px) {
    .my-nav {
        border-radius: 32px;
    }
}

@media (max-width: 767px ) {
    hr {
        display:none;
    }
    #language-dropdown-menu {
        left: 0% !important;
        width: 400% !important; 
    }
    #language-dropdown-toggle{
        margin-top: 0px !important;
    }
    .my-navbar-brand{
        display: table !important;
        margin: auto !important;
    }
    .my-nav-lang{
        margin-left: 0px !important;
    }
    li.my-nav-lang{
        list-style-type: none;
    }
    nav.my-nav{
        height: 58px;
    }
    .my-nav-list{
        background-color: rgb(130,150,150,0.77);
        border-radius: 10px 0px 10px 10px ;
    }
    li > .navbar__link.active, li > a.active-link {
        background-color: transparent !important;
        border-radius: 10px;
    }
    .navbar-toggler{
        height: 58px;
    }
    .navbar .dropdown-menu{
        position: relative !important;
    }

    .dropdown-border{
        display: none;
    }
    .border-nav{
        display: none;
    }
    .nav-link {
        font-size: 24px !important;
    }
    .navbar-collapse {
        overflow-y: scroll;
    }
} 

@media (max-width: 568px ) {
    .nav-link {
        font-size: 21px !important;
    }
}

@media (max-width: 568px ) {
    .nav-link {
        font-size: 21px !important;
    }
}

@media (max-width: 425px ) {
    .nav-link {
        font-size: 18px !important;
    }
    .my-nav {
        border-radius: 14px;
    }
}