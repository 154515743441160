.sitemap-container {
    padding : 50px;
    min-height : 200px;
    width : 100%;
    text-align: left;
}

.footer-logo{
    margin-left: 0px;
}

.sitemap-header {
    font-size: 28px;
    margin-bottom: 10px;
    font-style: normal !important;
    font-weight: 500 !important;
}

.sitemap-text {
    font-style: normal !important;
    font-weight: 400 !important;
}

.sitemap-link {
    font-size: 14px;
    color: white !important;
}


@media (max-width: 767px) { 
    .sitemap-container {
        text-align: center;
    }
    .footer-logo{
        display: table;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .sitemap-header{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) { 
    .sitemap-sub-container {
        margin-bottom: 20px;
    }
}