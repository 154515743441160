
.business-partnership-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {
    background-image: linear-gradient(90deg,#ff381a, #104268, #2fd21e);
    left: 0px;
    width: 40%;    
    margin-left: 0px;
}

.business-partnership-get-in-touch-container > .get-in-touch-container > .get-in-touch-button > .get-in-touch-icon {
    display: none;
}
.business-partnership-our-partners-title{
    font-size: 64px;
    display: table !important;
    margin: 0px auto;
}

@media (max-width: 767px) {
    .business-partnership-container{
        margin: 0px !important;
        max-width: fit-content !important;
    }
    .business-partnership-our-partners-title{
        font-size: 48px;
    }
    .business-partnership-get-in-touch-container > .get-in-touch-container > .get-in-touch-button {  
        margin-left: auto;
    }
}

@media (max-width: 568px) {
    .business-partnership-our-partners-title{
        font-size: 42px;
    }
}

@media (max-width: 475px) {
    .business-partnership-our-partners-title{
        font-size: 36px;
    }
}

@media (max-width: 375px) {
    .business-partnership-our-partners-title{
        font-size: 32px;
    }
}