#my-footer{
    border-radius: 60px 60px 0px 0px;
    position:relative;
}

.footer-container{
    background-color: black;
    min-height:230px;
    width:100%;
}
.copy-right-container {
    text-align: center;
    background-color: rgb(255,255,255,0.4);
    border-radius: 10px 10px 0px 0px;
}

.footer-text {
    color: white !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.footer-hr {
    display: block;
    height: 20px !important;
    border-style: inset !important;
    border-width: 1px !important;
    margin: 4px 4px !important;
}

.footer-link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
}

.footer-link {
    margin: 0% 2%;
    float: right;
}

.text-center{
    text-align: center;
}

@media(max-width: 1280px){
    .copy-right-container{
        text-align: left;
        padding-left: 20px;
    }
    .footer-link-container{
        padding-right: 20px;
    }
}

@media(max-width: 800px){
    .copy-right-container{
        font-size: 14px;
    }
    .footer-link-container{
        font-size: 14px;
    }
}

@media(max-width: 767px){
    .copy-right-text{
        text-align: center;
    }
    .footer-link-mobile-container{
        display: flex;
    }
    .footer-link {
        flex-basis: 33%;
        margin: auto;
        float: none;
    }
    .footer-text {
        text-align: center;
    }
    .copy-right-text {
        display: table;
        margin: auto;
    }
    .mobile-footer-link-container{      
        background-color: rgb(255,255,255,0.4);
        border-radius: 15px 15px 0px 0px;
    }
}

@media(max-width: 468px){
    .footer-text {
        font-size: 14px;
    }
}

@media(max-width: 375px){
    .footer-text {
        font-size: 13px;
    }
}