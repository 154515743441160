.third-party-item-title{
    font-size: 17px;
    text-align: center;
    display: table !important;
    margin: auto;
}

.third-party-item-content{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
}

.third-party-image-container{
    height: 40%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.third-party-image {
    width: 70%;
    margin: auto;
}

.third-party-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.third-party-item {
    background-color: rgb(228,228,229);
    padding: 2.5%;
    margin: 1%;
    border-radius: 50px;
    flex-basis: 25%;
}

@media (max-width: 1440px ) {
    .third-party-container{
        width: 80%;
    }
    .third-party-item-content{
        padding-top: 5px;
        margin-top: 0px;
    }
}

@media (max-width: 1200px ) {
    .third-party-container{
        width: 95%;
    }
}

@media (max-width: 1024px ) {
    .third-party-outer-container{
        margin-top: 40px;
    }
    .third-party-image-container{
        height: 50%;
    }
    .third-party-container{
        width: 95%;
        height: 400px;
    }
    .third-party-item{
        margin: auto;
        flex-basis: auto;
        height: 400px;
        width: 400px;
        padding: 10%;
    }
    .third-party-item-title{
        font-size: 26px;
    }
    .third-party-item-content{
        font-size: 18px;
    }
}

@media (max-width: 767px ) {
    .third-party-container{
        height: 360px;
    }
    .third-party-item{
        height: 360px;
        width: 360px;
    }
}

@media (max-width: 568px ) {
    .third-party-container{
        height: 300px;
    }
    .third-party-item{
        height: 300px;
        width: 300px;
    }
    .third-party-item-title {
        font-size: 21px;
    }
    .third-party-item-content {
        font-size: 16px;
    }
    .third-party-image-container {
        margin-top: 10px;
    }
}

@media (max-width: 425px ) {
    .third-party-container{
        height: 240px;
    }
    .third-party-item{
        height: 240px;
        width: 240px;
    }
    .third-party-item-title {
        font-size: 18px;
    }
    .third-party-item-content {
        font-size: 14px;
    }
    .third-party-image-container {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}