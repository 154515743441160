/* color css */
.base-color-grey {
    color: #f4f4f4;
}
.base-color-deep-grey {
    color: #d3d3d3;
}
.base-color-black {
    color: #212529;
}
.base-footer-bg-color {
    background-color: rgb(93, 114, 114, 0.33);
}
.base-bg-color-grey {
    background-color: #f4f4f4;
}
.base-bg-color-deep-grey {
    background-color: #d3d3d3;
}
.base-color-orange {
    color: #F05422;
}
.base-bg-color-orange {
    background-color: #F05422;
}
.base-bg-color-grey{
    background-color: rgb(119, 141, 141, 0.4);
}
.base-color-blue {
    color: #0D4673;
}
.base-color-light-blue {
    color: rgb(54, 179, 240);
}
.base-color-green {
    color: rgb(84, 255, 46);
}
.base-bg-color-blue {
    background-color: #0D4673;
}

.gradient-left-to-right-2c-2{
    background-image: linear-gradient(90deg, #ffffff, #ff9675);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline-block;
}

.gradient-left-to-right-3c{
    background-image: linear-gradient(90deg, #ff4021, #ff8017, #2987ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline-block;
}

.gradient-left-to-right-2c{
    background-image: linear-gradient(90deg, #ff4021, #2987ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline-block;
}

.gradient-top-to-bottom-2c{
    background-image: linear-gradient(180deg, #ff4021, #2987ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline-block;
}