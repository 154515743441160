.wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
    margin : 0px;
}


@media (max-width: 1440px ) {
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width : 40%;
    }
    .section-outscope-top-sticky-image{
        top : 25%;
    }
    .section-outscope-bottom-sticky-image{
        top : -25%;
    }
}

@media (max-width: 1280px ) {
    .section-outscope-top-sticky-image{
        top : 35%;
    }
}

@media (max-width: 1120px ) {
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width : 50%;
    }
    .section-outscope-top-sticky-image{
        top : 40%;
    }
}

@media (max-width: 1024px ) {
    .section-outscope-top-sticky-image{
        top : 45%;
    }
    .section-benefit{
        margin-top: 0px;
    }
}

@media (max-width: 968px ) {
    .section-outscope-top-sticky-image{
        top : 50%;
    }
}

@media (max-width: 888px ) {
    .section-outscope-top-sticky-image{
        top : 60%;
    }
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width : 60%;
    }
}

@media (max-width: 800px ) {
    .section-outscope-top-sticky-image{
        top : 65%;
    }
}

@media (max-width: 767px) {
    .section-outscope-top-sticky-image{
        top : 0;
    }
    .section-outscope-bottom-sticky-image{
        top : 0;
    }
    .section-benefit{
        margin-top: 20px !important;
    }
    .wechat-mini-program-section-table{
        min-height: auto;
    }
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        margin : auto;
        width: 40%;
    }
}

@media (max-width: 568px) {
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width: 50%;
    }
}
@media (max-width: 475px) {
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width: 60%;
    }
}

@media (max-width: 375px) {
    .wechat-mini-program-try-it-now-container > .try-it-now-container > .try-it-now-button {
        width: 75%;
    }
}