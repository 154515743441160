.get-in-touch-container{
    position: relative;
    height: 50px;
    font-size: 16px;
}

.get-in-touch-button{
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 30%;
    border-radius: 15px;
    border: none;
    background-image: linear-gradient(90deg,#ff8017, #2987ff);
    color: white;
    text-align: center;
    line-height: 50px;
    margin: auto;
}

.get-in-touch-icon{
    position: absolute;
    width: 20px;
    right: 8%;
    top: 25%;
}