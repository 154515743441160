.banner-bg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.banner-slogan {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 991px ) {
    .banner-slogan {
        font-size: 36px;
    }
} 

@media (max-width: 767px ) {
    .banner-slogan {
        font-size: 32px;
    }
} 

@media (max-width: 575px ) {
    .banner-slogan {
        font-size: 28px;
    }
} 