/* Background container */
.bg-white-container{
    background-color: white;
}
.bg-grey-container{
    background-color: #f4f4f4;
}

.home-banner-word{
    font-size: 24px;
    color: white;
}

.home-banner-smaller-word{
    font-size: 18px;
    color: white;
}

.banner-crypto-currency-count{
    font-size: 144px;
}

.custom-base-count{
    font-size: 144px;
}

/* Advantage section */
.box-container {
    text-align: center;
    display: block;
    /*
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.025);
    transform: translate3d(0, -2px, 10px);
    */
}

.box-img-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.box-img-container img {
    width:60px;
    height:60px;
}

.box-content {
    padding: 20px;
}
/* container section */
.home-container {
    min-height: 300px;
    margin-bottom: 40px;
    /*box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.0);*/
}

.integration-container {
    background-color: white;
}

/* Service section */
.service-img-container {
    text-align: center;
    display: block;
}

.href-img:hover {
    cursor: pointer;
}

.service-img-container img {
    width: 360px;
    height: 240px;
}

/* Integration section */
#integration-logos ul {
    display: flex;
    padding-left: 0px;
}

#integration-logos li {
    margin: 0px;
    list-style: none;
}
#integration-logos img {
    height: 60px;
    vertical-align: middle;
    margin: 0px 20px;
}

/* Merchant section */
#merchants-logos {
    width: 100%;
    text-align:center;
}
.merchants {
    width: 840px;
    height: 504px;
    text-align: center;
}

/* Common section */
.learn-more-link {
    color: #F05422;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
}
.learn-more-link:hover {
    color: black !important;
    text-decoration: none;
}

.stay-connected-statement {
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px;
    text-transform:uppercase;
}

.stay-connected-btn-container {
    float: left;
}

.home-stay-connected-form-error {
    color: red;
}

.home-newsletter-btn {
    border: 2px solid #f05422 !important;
    border-radius: 20px !important;
    padding: 5px 15px;
}

.home-newsletter-btn:hover {
    background-color: #f05422 !important;
    color: white !important;
}

.promotion-container {
    margin-top : 90px;
    position: relative;
}

.home-left-circle {
    position: absolute;
    width: 30%;
    top: -5%;
}

.home-right-circle {
    position: absolute;
    width: 45%;
    top: 5%;
    right: 0%;
}

.filter-container {
    background : #8E8E8E;
    background : rgba(142, 142, 142, 0.4);
    border-radius : 50px;
    -moz-border-radius : 50px;
    -webkit-border-radius : 50px;
}

.home-join-us-container > .row > .col > .section-table > .section-content > .submit-email-container > .submit-email-button{
    background-image: linear-gradient(to right bottom,#ff8017, #2987ff);
}

.home-customer-increment-img{
    width: 80%;
    float: auto;
}

.home-digital-phone{
    width: 80%;
    margin-left: 0px;
    margin-top: -5%;
}

.third-party-intro-content{
    display: table;
    margin: 0px auto;
    width: 60%;
    text-align: center;
}
.home-lab-logo{
    width: 35%;
}
.custom-base-count-2{
    font-size: 64px;
}

.home-support-number{
    font-size: 64px;
}

.home-hr{
    width: 10%;
    margin-left: 0px;
}

.home-experience-word{
    font-size: 96px;
}

.home-most-prominent-word{
    font-size: 96px;
}

.home-business-partnership-title{
    font-size: 64px;
    display: table !important;
    margin: 0 auto;
}

@media (max-width: 1024px ) {
    .custom-base-count{
        font-size: 96px;
    }
    .banner-crypto-currency-count{
        font-size: 96px;
    }
    .home-support-number{
        font-size: 54px;
    }
    .home-business-partnership-title{
        font-size: 48px;
    }
} 

@media (max-width: 767px ) {
    #integration-logos ul {
        display: block;
    }
    .home-customer-increment-img{
        width: 40%;
        float: right;
    }
    .home-digital-phone{
        width: 80%;
        margin: auto;
    }
    .home-digital-phone-container{
        padding-bottom: 40px;
    }
    .promotion-container{
        margin-top: 40px;
    }
    .third-party-intro-content{
        width: 100%;
    }
    .custom-base-count{
        font-size: 64px;
    }
    .banner-crypto-currency-count{
        font-size: 64px;
    }
    .home-lab-logo{
        width: 65%;
    }    
    .home-support-number{
        font-size: 36px;
    }
    .banner-section-content{
        text-align: left;
    }
    .home-hr{
        display: table;
        margin: auto;
    }
    .home-container{
        text-align: center !important;
    }
    .world-wide-new-customers-base-container{
        display:table;
        margin: auto;
    }
    .home-customer-increment-img{
        display: table;
        margin: auto;
        float: unset;
        margin: 20px auto;
    }
    .mobile-center-wording{
        display: table;
        margin: auto;
    }
    .home-hr{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .custom-base-count-2{
        font-size: 74px;
    }
    .home-promotion-text{
        font-size: 21px;
    }
    .home-prominent-word{
        font-size: 74px;
    }
    .home-most-prominent-word{
        font-size: 74px;
    }
    .banner-crypto-currency-count-text{
        display: flex;
        justify-content: center;
    }
    .experience-mobile-text{
        text-align: center !important;
    }
} 

@media (max-width: 568px ) {
    
    .consulting-reminder{
        font-size: 16px !important;
    }
    .custom-base-count-2{
        font-size: 48px;
    }
    .home-most-prominent-word{
        font-size: 64px;
    }
    .home-prominent-word{
        font-size: 48px;
    }
    .home-promotion-text{
        font-size: 18px;
    }
    .custom-base-count{
        font-size: 54px;
    }
    .banner-crypto-currency-count{
        font-size: 54px;    
    }
    .home-business-partnership-title{
        font-size: 36px;
    }
}
@media (max-width: 468px ) {
    .home-promotion-text{
        font-size: 16px;
    }
    .home-most-prominent-word{
        font-size: 60px;
    }
    .home-business-partnership-title{
        font-size: 32px;
    }
}
@media (max-width: 425px ) {
    .home-promotion-text{
        font-size: 14px;
    }
    .home-most-prominent-word{
        font-size: 48px;
    }
    .home-business-partnership-title{
        font-size: 28px;
    }
}
@media (max-width: 373px ) {
    .custom-base-count{
        font-size: 48px;
    }
    .custom-base-count-2{
        font-size: 36px;
    }
    .home-promotion-text{
        font-size: 11px;
    }
    .home-most-prominent-word{
        font-size: 36px;
    }
}